import { NavLink } from "types";

export const BREAKPOINTS = Object.freeze({
  sm: "33.75rem",
  md: "48rem",
  lg: "64rem",
  xl: "85.5rem",
});

export const TAG_SIZE_THRESHOLDS = Object.freeze({
  xs: 0,
  sm: 50,
  md: 100,
  lg: 250,
  xl: 500,
  xxl: 1000,
});

export const NAV_LINKS = [
  {
    key: "about-us",
    href: "/about-us",
  },
  {
    key: "projects",
    href: "/projects",
  },
  {
    key: "career",
    href: "/career",
  },
  // {
  //   key: "blog",
  //   href: "/blog",
  // },
] as const satisfies readonly NavLink[];
